
import { Component, Vue, Inject, Prop } from "vue-property-decorator";
import { DependencyContainer } from "tsyringe";
import _ from "lodash";

import { PROVIDER, SERVICES as S } from "@/config/literals";

import UpdateUi from "@/components/Core/Update/UpdateUI.vue";
import SocietyAutocomplete from "@/components/General/Autocompletes/SocietyAutocomplete.vue";

import { SocietyInfos } from "@/domain";

import { ISocietyService } from "@/services";

@Component({
  components: {
    UpdateUi,
    SocietyAutocomplete,
  },
})
export default class SocietyUpdate extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  item!: SocietyInfos;

  @Prop({ required: true })
  forbiddenNames!: string[];

  get societyService() {
    return this.container.resolve<ISocietyService>(S.SOCIETY_SERVICE);
  }

  loading: boolean = false;
  name: string | null = null;

  mounted() {
    this.name = this.item.name;
  }

  async update() {
    try {
      this.loading = true;
      const societyUpdated = await this.societyService.update(this.item.id, {
        name: this.name!,
        disabled: this.item.disabled,
      });
      this.$emit("update", societyUpdated);
    } finally {
      this.loading = false;
    }
  }

  isAllowed(name: string) {
    return (
      _.findIndex(
        this.forbiddenNames,
        (v) => v.toUpperCase() == name.toUpperCase()
      ) != -1 && this.item.name.toUpperCase() != name.toUpperCase()
    );
  }
}
