
import { Component, Vue, Prop, Inject } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";
import { PROVIDER, SERVICES as S } from "@/config/literals";

import { ISocietyService } from "@/services";

import { SocietyInfos } from "@/domain";

@Component
export default class SocietyAutocomplete extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  value!: string | null;

  @Prop({ required: false, default: null })
  societyIdToInclude!: string | null;

  get societyService() {
    return this.container.resolve<ISocietyService>(S.SOCIETY_SERVICE);
  }

  get filteredSocieties() {
    const societies = _.filter(
      this.societies,
      (s) => !s.disabled || s.id == this.societyIdToInclude
    );

    return _.map(societies, (s) => {
      return {
        id: s.id,
        name: s.name,
      };
    });
  }

  societies: SocietyInfos[] = [];
  loading: boolean = false;

  mounted() {
    this.fetch();
  }

  async fetch() {
    try {
      this.loading = true;
      this.societies = await this.societyService.getMany({
        ids: [],
        includeEnabled: true,
        includeDisabled: true,
      });
    } finally {
      this.loading = false;
    }
  }
}
